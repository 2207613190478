import { PHASES, CHANNELS, ACTIONS } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';

export const getSession = () =>
  new Promise((resolve) => {
    socket.emit(CHANNELS.SESSION);

    const SESSION = (res) => {
      appState.session = res;
      resolve();
    };

    socket.once(CHANNELS.SESSION, SESSION);
  });

const joinRound = () =>
  new Promise((resolve) => {
    socket.emit(CHANNELS.ONGOING_ROUND);

    const ONGOING_ROUND = ({ roundId, phase }) => {
      appState.roundId = roundId;
      socket.emit(CHANNELS.JOIN_ROUND, { roundId });
      socket.off(CHANNELS.ONGOING_ROUND, ONGOING_ROUND);
      resolve(phase);
    };

    socket.once(CHANNELS.ONGOING_ROUND, ONGOING_ROUND);
  });

export const joinOngoingRound = () =>
  new Promise((resolve) => {
    joinRound().then((phase) => {
      if (phase === PHASES.MULTIPLIER_UPDATE) phase = PHASES.GAME_STARTED;
      if (phase === PHASES.WAITING) phase = PHASES.GAME_ENDED;
      appState.initPhase = phase;
      resolve();
    });
  });

export const syncBets = () =>
  new Promise((resolve) => {
    socket.emit(CHANNELS.CURRENT_BETS);

    const CURRENT_BETS = (res) => {
      res.forEach((bet) => {
        appState.bet[bet.betId] = {
          betValue: bet.betAmount,
          autoCashOut: bet.maxMultiplier,
          action: appState.initPhase === PHASES.BETTING ? ACTIONS.CANCEL : ACTIONS.CASH_OUT,
          type: bet.type,
        };
      });

      resolve();
    };

    socket.once(CHANNELS.CURRENT_BETS, CURRENT_BETS);
  });

export const phaseTime = () =>
  new Promise((resolve) => {
    socket.emit(CHANNELS.ONGOING_ROUND_TIME);

    const ONGOING_ROUND_TIME = ({ passed }) => {
      appState.offsetTime = passed;
      resolve();
    };

    socket.once(CHANNELS.ONGOING_ROUND_TIME, ONGOING_ROUND_TIME);
  });
