import { useContext } from 'react';
import { ACTIONS } from '@utils/enums';
import useMultiplier from '@hooks/socket/useMultiplier';
import LButton from '../../../../../../components/button';
import { FreeBetContext } from '../../../../../../context/free-bet';
import { buttonVariantsMap } from '../../helpers';
import CashOut from '../cashout';

const variant = buttonVariantsMap[ACTIONS.CASH_OUT];

const FreeCashOut = ({ loading, setLoading, betValue, autoCashout, onClick }) => {
  const multiplier = useMultiplier();
  const { suggestions } = useContext(FreeBetContext);

  const suggestion = suggestions.find(({ betNominal }) => betNominal === betValue);

  const disabled = suggestion?.minMultiplier > multiplier;

  return (
    <LButton disabled={disabled} loading={loading} variant={variant} onClick={onClick}>
      <CashOut autoCashout={autoCashout} betValue={betValue} setLoading={setLoading} />
    </LButton>
  );
};

export default FreeCashOut;
