import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { ACTIONS } from '@utils/enums';
import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import usePrevious from '@hooks/usePrevious';
import Toggle from '@components/toggle';
import Typography from '@components/typography';
import Input from '../../../../../components/input';
import { ActionContext } from '../../../../../context/action';
import { FreeBetContext } from '../../../../../context/free-bet';
import styles from './index.module.css';

const l = getLocalize('game.bet');

const CashOut = ({ betId, betValue, checked, initValue, setAutoCashOut }) => {
  const { minAutoCashout, maxAutoCashout, defaultAutoCashout } = appState.provider;

  const savedAutoCashOut = useRef(appState.bet[betId].autoCashOut);

  const { isFreeBet, suggestions } = useContext(FreeBetContext);
  const prevIsFreeBet = usePrevious(isFreeBet);

  const suggestion = useMemo(
    () => isFreeBet && suggestions.find(({ betNominal }) => betNominal === betValue),
    [betValue, isFreeBet, suggestions]
  );

  const [maxMultiplier, setMaxMultiplier] = useState(initValue || defaultAutoCashout);

  const { action } = useContext(ActionContext);
  const disabled = action !== ACTIONS.BET;

  const onChange = (checked) => {
    setAutoCashOut(checked ? maxMultiplier : null);
  };

  useEffect(() => {
    if (prevIsFreeBet === isFreeBet) return;
    savedAutoCashOut.current = null;
    setAutoCashOut(false);
  }, [isFreeBet, prevIsFreeBet, setAutoCashOut]);

  const freeMinCashout = suggestion?.minMultiplier;

  useEffect(() => {
    const value = savedAutoCashOut.current || freeMinCashout || minAutoCashout;
    setMaxMultiplier(value);
  }, [minAutoCashout, freeMinCashout, isFreeBet]);

  const setMultiplier = (value) => {
    setMaxMultiplier(value);
    if (checked) setAutoCashOut(value);
  };

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  return (
    <div className={containerClasses}>
      <span className={styles.label}>
        <Typography variant="regular" size="12">
          {l('autoCashOut')}
        </Typography>
      </span>
      <Toggle checked={checked} onChange={onChange} />
      <Input
        label={l('cashOut')}
        suffix="x"
        disabled={!checked}
        value={maxMultiplier}
        setValue={setMultiplier}
        min={suggestion?.minMultiplier || minAutoCashout}
        max={maxAutoCashout}
      />
    </div>
  );
};

export default CashOut;
