import { useCallback, useContext, useEffect, useState } from 'react';
import { PHASES, ACTIONS, BET_ERRORS } from '@utils/enums';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';
import usePrevious from '@hooks/usePrevious';
import { listenSocketError } from '@game/helpers';
import LButton from '../../../../components/button';
import { ActionContext } from '../../../../context/action';
import { FreeBetContext } from '../../../../context/free-bet';
import { listenAutoCashout, betPlaced, buttonVariantsMap, playBetSound, showError } from './helpers';
import CashOut from './parts/cashout';
import FreeCashOut from './parts/free-cashout';

const Button = ({ betId, betValue, autoCashout, autoBet, setAutoBet, onBettingChange }) => {
  const { currency } = appState.provider;

  const phase = useContext(PhaseContext);
  const prevPhase = usePrevious(phase);

  const { action, setAction } = useContext(ActionContext);
  const prevAction = usePrevious(action);

  const { isFreeBet, leftSuggestions } = useContext(FreeBetContext);

  const [loading, setLoading] = useState(false);

  const isBetting = phase === PHASES.BETTING;

  const waitBet = useCallback(
    ({ action }) => {
      setLoading(true);
      betPlaced({ betId, action }).finally(() => setLoading(false));
    },
    [betId]
  );

  const sendBet = useCallback(
    ({ action }) => {
      onBettingChange({ action });
      if (action !== ACTIONS.CASH_OUT) waitBet({ action });
    },
    [onBettingChange, waitBet]
  );

  const onActionChange = useCallback(
    ({ action, next, auto }) => {
      setAction(next);
      if ([ACTIONS.BET, ACTIONS.CANCEL].includes(action)) playBetSound();
      if (action === ACTIONS.CANCEL && !auto) setAutoBet(false);
      if (!isBetting && action !== ACTIONS.CASH_OUT) return;
      if (autoBet && action === ACTIONS.CASH_OUT) onActionChange({ action: ACTIONS.BET, next: ACTIONS.CANCEL });

      if (!auto) sendBet({ action });
    },
    [autoBet, isBetting, sendBet, setAutoBet, setAction]
  );

  useEffect(() => {
    if (autoBet && action === ACTIONS.BET) onActionChange({ action, next: ACTIONS.CANCEL });
  }, [autoBet, action, onActionChange]);

  const onClick = () => {
    onActionChange({ action, next: action === ACTIONS.BET ? ACTIONS.CANCEL : ACTIONS.BET });
  };

  useEffect(() => {
    const CLIENT_ERRORS = ({ error, betId: errorBetId }) => {
      if (errorBetId !== betId) return;

      showError(error);

      if (BET_ERRORS.includes(error)) {
        const next = action === ACTIONS.CANCEL ? ACTIONS.BET : ACTIONS.CANCEL;
        onActionChange({ action, next, auto: true });

        setAutoBet(false);
        setLoading(false);
      }
    };

    return listenSocketError(CLIENT_ERRORS);
  }, [setAutoBet, onActionChange, action, betId]);

  useEffect(() => {
    if (prevPhase === phase) return;

    if (phase === PHASES.GAME_ENDED && action === ACTIONS.CASH_OUT) {
      onActionChange({ action, next: ACTIONS.BET, auto: true });
    }
    if (phase === PHASES.GAME_STARTED && action === ACTIONS.CANCEL) setAction(ACTIONS.CASH_OUT);
    if (phase === PHASES.BETTING && action === ACTIONS.CANCEL) sendBet({ action: ACTIONS.BET });
  }, [phase, prevPhase, action, setAction, sendBet, onActionChange]);

  useEffect(() => {
    const AUTO_CASH_OUT = ({ betId: resBetId }) => {
      if (resBetId !== betId) return;

      setTimeout(() => {
        setLoading(false);
        onActionChange({ action, next: ACTIONS.BET, auto: true });
      }, 100);
    };

    return listenAutoCashout(AUTO_CASH_OUT);
  }, [action, betId, onActionChange]);

  const buttonAction = loading ? prevAction : action;
  const waiting = buttonAction === ACTIONS.CANCEL && !isBetting;

  if (buttonAction === ACTIONS.CASH_OUT && isFreeBet) {
    return (
      <FreeCashOut
        loading={loading}
        autoCashout={autoCashout}
        betValue={betValue}
        setLoading={setLoading}
        onClick={onClick}
      />
    );
  }

  const variant = waiting ? ACTIONS.WAITING : buttonAction === ACTIONS.BET && isFreeBet ? ACTIONS.FREE : buttonAction;
  const disabled = variant === ACTIONS.FREE && !leftSuggestions.find(({ betNominal }) => betNominal === betValue);

  const cashOutAmount = buttonAction === ACTIONS.CASH_OUT && (
    <CashOut betValue={betValue} autoCashout={autoCashout} setLoading={setLoading} />
  );

  return (
    <LButton loading={loading} disabled={disabled} variant={buttonVariantsMap[variant]} onClick={onClick}>
      {cashOutAmount || betValue?.toFixed(2)} {currency}
    </LButton>
  );
};

export default Button;
