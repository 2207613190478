import { useContext, useRef, useEffect, useMemo } from 'react';
import { ACTIONS } from '@utils/enums';
import { clamp, classNames, validateBet } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Icon from '@components/icon';
import Input from '../../components/input';
import { ActionContext } from '../../context/action';
import Suggestions from './parts/suggestions';
import styles from './index.module.css';

const l = getLocalize('game.bet');

const BetInput = ({ betId, betValue, setBetValue }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);
  const { maxBet, minBet, currency, defaultBet } = appState.provider;
  const defaultValue = useRef();

  const { action } = useContext(ActionContext);
  const disabled = action !== ACTIONS.BET;

  useEffect(() => {
    setBetValue(betState.betValue || defaultBet);
  }, [betState, defaultBet, setBetValue]);

  useEffect(() => {
    betState.betValue = betValue;
  }, [betState, betValue]);

  const onStep = (sign) => {
    const newValue = Number(betValue) + sign * defaultBet;
    const validValue = validateBet({ value: newValue, min: minBet, max: maxBet });
    setBetValue(validValue);
  };

  const setSuggestion = (value) => {
    const clampValue = (current) => clamp(minBet, current, maxBet);

    if (value === defaultValue.current) setBetValue((prev) => clampValue(prev + value));
    else {
      defaultValue.current = value;
      setBetValue(clampValue(value));
    }
  };

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  return (
    <div className={containerClasses}>
      <div className={styles.inputContainer}>
        <span className={styles.icon} onClick={() => onStep(-1)}>
          <Icon icon="Minus" />
        </span>
        <Input label={l('bet')} suffix={currency} value={betValue} setValue={setBetValue} min={minBet} max={maxBet} />
        <span className={styles.icon} onClick={() => onStep(1)}>
          <Icon icon="Plus" />
        </span>
      </div>
      <Suggestions onClick={setSuggestion} />
    </div>
  );
};

export default BetInput;
