import { appState } from '@utils/store';
import Typography from '@components/typography';
import useBalance from '@game/parts/balance/useBalance';
import styles from './index.module.css';

const Balance = () => {
  const balance = useBalance();
  const { currency } = appState.provider;

  return (
    <div className={styles.container}>
      <Typography variant="bold" size="16">
        {balance}
      </Typography>
      <Typography variant="bold" size="12">
        {currency}
      </Typography>
    </div>
  );
};

export default Balance;
